export const dsn = () => {
  try {
    if (import.meta.env.VITE_SENTRY_DSN) {
      return import.meta.env.VITE_SENTRY_DSN;
    }
  } catch (error) {
    console.warn('Error getting VITE_SENTRY_DSN from vite', error);
  }

  try {
    return process?.env?.VITE_SENTRY_DSN;
  } catch (error) {
    console.error('Error getting VITE_SENTRY_DSN', error);
    throw error;
  }
}
