/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { replayIntegration } from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { dsn } from "~/shared/sentry";
import { domain } from "~/shared/domain";

if (!domain().startsWith('http://localhost')) {
  Sentry.init({
    dsn: dsn(),
    tracesSampleRate: 1,
    denyUrls: ['http://localhost', 'http://localhost:5173'],
  
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches
      }),
      replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
  
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
